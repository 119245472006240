/* IMPORTS START */
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import Http            from '@/lib/data/api/Http'
import GQL             from '@/lib/data/api/GQL'
import Home            from '@/api/endpoint/Home'
/* IMPORTS END */

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },

  get GQLRequest () {
    return GQL.Request
  },

  get Resource () {
    return {
      /* RESOURCES */
      Home: Home
    }
  },

  get Endpoint () {
    return {
      /* ENDPOINTS */
      Home: {
        Get: {
          Path  : 'election/vote/{hash}',
          Method: Http.Method.Get
        },
        Save: {
          Path  : 'election/vote/{hash}',
          Method: Http.Method.Post
        }
      }
    }
  },

  responseData     : (response) => response?.data?.data || null,
  responseErrors   : (response) => response?.data?.errors || null,
  isResponseSuccess: (response) => response?.status === ApiResponseEnum.SUCCESS && response.data?.success && API.responseData(response) && !API.responseErrors(response)
}

export { default as Http } from '@/lib/data/api/Http'
export default API
