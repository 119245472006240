export default {
  Title: 'Εκλογές',

  Step: {
    1: 'ΠΛΗΡΟΦΟΡΙΕΣ',
    2: 'ΨΗΦΟΔΕΛΤΙΟ',
    3: 'ΥΠΟΒΟΛΗ'
  },

  Uid: {
    Label: 'Αριθμός Ταυτότητας Ψηφοφόρου'
  },

  Options: 'Έως {maxVotes} επιλογές',

  Message: {
    NoSelection: 'Χωρίς επιλογές',
    Success    : 'Το ψηφοδέλτιο καταχωρήθηκε επιτυχώς!'
  }
}
