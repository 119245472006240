<template>
  <data-card
    v-if="model && model.Id"
    :color="'primary'"
    :colored-border="true"
    :icon="'mdi-vote'"
    :sub-title="model.Description"
    :title="model.Name"
    min-width="280"
    width="650"
  >
    <template #toolbar-append>
      <v-tooltip
        bottom
        color="primary"
      >
        <template #activator="{on}">
          <v-btn
            color="primary"
            href="https://voting.odh.gr/downloads/voting-platform-help.pdf"
            icon
            target="_blank"
            v-on="on"
          >
            <v-icon large>
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </template>

        <span>
          Οδηγίες Ψηφοφορίας
        </span>
      </v-tooltip>
    </template>

    <v-card-text>
      <v-stepper
        v-model="voteStep"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header
          v-if="voteStep > 1 && voteStep < 4"
          :class="[{'grey lighten-3': !$vuetify.theme.dark}]"
        >
          <v-stepper-step
            :complete="voteStep > 1"
            step="1"
          >
            {{ $t('Vote.Step.1') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="voteStep > 2"
            step="2"
          >
            {{ $t('Vote.Step.2') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="voteStep > 3"
            step="3"
          >
            {{ $t('Vote.Step.3') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            :class="[{'grey lighten-3': !$vuetify.theme.dark}]"
            class="text-center rounded"
            step="1"
          >
            <div class="title">
              {{ model.Name }}
            </div>
            <div class="title">
              {{ model.CompanyName }}
            </div>

            <div class="ma-4">
              <v-img
                :src="`https://api.voting.odh.gr/storage/logo/${model.CompanyId}.png`"
                contain
                height="200"
                position="center"
              />
            </div>

            <div class="subtitle-2">
              {{ model.Description }}
            </div>

            <div class="grey--text font-weight-medium">
              {{ model.StartedAt }} - {{ model.EndedAt }}
            </div>

            <v-alert
              v-if="model.Notes && model.ActionStartedAt"
              class="mt-4 editor-content"
            >
              <div
                class="caption"
                v-html="model.Notes"
              />
            </v-alert>

            <div
              v-if="electionIsOpenForVotes"
              class="mt-6 mb-3"
            >
              <v-text-field
                v-model="voterUid"
                :append-icon="step1Valid ? 'mdi-check-circle' : ''"
                :class="step1Valid ? 'success' : ''"
                :clearable="!step1Valid"
                :color="step1Valid ? 'white' : ''"
                :label="$t('Vote.Uid.Label')"
                :readonly="step1Valid"
                filled
                hide-details="auto"
              />
            </div>
          </v-stepper-content>

          <v-stepper-content
            :class="[{'grey lighten-3': !$vuetify.theme.dark}]"
            class="pa-0 rounded"
            step="2"
          >
            <v-list
              color="transparent"
              dense
              nav
              tile
            >
              <template v-for="(category, index) in electionCategories">
                <v-subheader
                  :key="`category-${index}`"
                  class="subtitle-2 font-weight-bold primary rounded my-3"
                  dark
                >
                  {{ category.Name }}

                  <v-spacer />

                  <span
                    class="caption primary--text text--lighten-3"
                  >{{ $t('Vote.Options', {maxVotes: category.MaxVotes}) }}</span>
                </v-subheader>

                <v-list-item-group
                  :key="`list-item-group-${index}`"
                  v-model="category.SelectedCandidates"
                  :max="category.MaxVotes"
                  active-class="primary--text"
                  multiple
                >
                  <template v-for="(candidate, idx) in category.Candidates">
                    <v-list-item
                      :key="`category-${index}-candidate-${idx}`"
                      :value="candidate"
                    >
                      <template #default="{active}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ candidate.LastName }} {{ candidate.FirstName }}
                            {{ candidate.FathersName ? `του ${candidate.FathersName}` : '' }} <span
                              v-if="candidate.Corps"
                              class="grey--text caption"
                            >({{ candidate.Corps }})</span>
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            readonly
                          />
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </template>
            </v-list>
          </v-stepper-content>

          <v-stepper-content
            :class="[{'grey lighten-3': !$vuetify.theme.dark}]"
            class="pa-0 rounded"
            step="3"
          >
            <v-list
              color="transparent"
              dense
              nav
              tile
            >
              <template v-for="(category, index) in electionCategories">
                <v-subheader
                  :key="`category-${index}`"
                  class="subtitle-2 font-weight-bold primary rounded my-3"
                  dark
                >
                  {{ category.Name }}
                </v-subheader>

                <template v-for="(candidate, idx) in category.SelectedCandidates">
                  <v-list-item
                    :key="`category-${index}-candidate-${idx}`"
                    :value="candidate"
                  >
                    <template #default>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ candidate.LastName }} {{ candidate.FirstName }}
                          {{ candidate.FathersName ? `του ${candidate.FathersName}` : '' }} <span
                            v-if="candidate.Corps"
                            class="grey--text caption"
                          >({{ candidate.Corps }})</span>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon color="success">
                          mdi-check
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>

                <template v-if="!category.SelectedCandidates || !category.SelectedCandidates.length">
                  <div
                    :key="`category-${index}-candidate-no-selection`"
                    class="grey--text caption ml-2"
                  >
                    {{ $t('Vote.Message.NoSelection') }}
                  </div>
                </template>
              </template>
            </v-list>

            <div
              v-if="!!serverErrorMessage"
              class="pa-4 pb-0"
            >
              <v-alert
                dense
                outlined
                type="error"
              >
                {{ serverErrorMessage }}
              </v-alert>
            </div>
          </v-stepper-content>

          <v-stepper-content
            :class="[{'grey lighten-3': !$vuetify.theme.dark}]"
            class="text-center rounded"
            step="4"
          >
            <v-icon
              color="success"
              size="128"
            >
              mdi-check-circle
            </v-icon>

            <div class="subtitle-1 mt-4">
              {{ $t('Vote.Message.Success') }}
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-divider />

    <v-card-actions
      v-if="voteStep < 4 && electionIsOpenForVotes"
      class="pa-4"
    >
      <v-spacer />

      <v-btn
        v-if="voteStep <= 3"
        :disabled="voteStep === 1 || isLoading"
        class="ml-1"
        color="primary"
        outlined
        @click="onPrevStep"
      >
        {{ $t('Common.Button.Prev') }}
      </v-btn>

      <v-btn
        v-if="voteStep < 3"
        :disabled="isLoading || !step1Valid"
        class="ml-1"
        color="primary"
        depressed
        @click="onNextStep"
      >
        {{ $t('Common.Button.Next') }}
      </v-btn>

      <v-btn
        v-if="voteStep === 3"
        :disabled="isLoading"
        :loading="isLoading"
        class="ml-1"
        color="success"
        depressed
        @click="onSave"
      >
        {{ $t('Common.Button.Submit') }}
      </v-btn>
    </v-card-actions>
  </data-card>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import VoteModel from '@/api/models/home/VoteModel'
import DataCard from '@/theme/default/components/common/DataCard.vue'
import API from '@/api/Api'
import AutoUpdateApiData from '@/mixins/api/autoUpdateApiData'

export default {
  name      : 'Vote',
  components: { DataCard },
  directives: {},
  mixins    : [i18nRouteMeta, AutoUpdateApiData],
  props     : {},
  data () {
    return {
      voteStep                : 1,
      isLoading               : false,
      voterUid                : '',
      componentData           : null,
      serverErrorMessage      : '',
      autoUpdateApiDataOptions: {
        timeout  : 15000,
        autoStart: true,
        callback : 'getData'
      }
    }
  },
  computed: {
    step1Valid () {
      return this.voterUid === this.model.Uid
    },

    electionIsOpenForVotes () {
      return !!this.model.ActionStartedAt && !this.model.ActionEndedAt
    },

    electionCategories () {
      return this.model?.Categories || []
    },

    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onNextStep () {
      this.serverErrorMessage = ''
      if (this.voteStep < 3) this.voteStep++
      if (this.voteStep > 1) this.stopAutoUpdateApiData()
    },

    onPrevStep () {
      this.serverErrorMessage = ''
      if (this.voteStep > 0) this.voteStep--
      if (this.voteStep === 1) this.startAutoUpdateApiData(this.getData)
    },

    onSave () {
      this.serverErrorMessage = ''
      this.isLoading = true
      const data = this.model.clone()
      const payload = {
        Id        : data.Id,
        Uid       : data.Uid,
        MemberId  : data.MemberId,
        Candidates: []
      }

      data.Categories.forEach(category => {
        const selectedCandidates = category?.SelectedCandidates || []
        selectedCandidates.forEach(candidate => {
          payload.Candidates.push(candidate.CandidateId)
        })
      })

      const hash = this.$route?.params?.hash || ''
      API.Resource.Home.Save(hash, payload)
        .then(response => {
          this.handleSaveResponse(response)
        })
        .catch(e => {
          this.handleSaveResponse(e.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleSaveResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.onSaveSuccess(response, data)
      } else {
        this.onSaveError(response, errors)
      }
    },
    onSaveSuccess (response, data) {
      this.stopAutoUpdateApiData()
      this.voteStep = 4
    },
    onSaveError (response, errors) {
      this.serverErrorMessage = response?.data?.message || ''
    },

    getData () {
      const hash = this.$route?.params?.hash || ''
      API.Resource.Home.Get(hash)
        .then(response => {
          this.handleGetDataResponse(response)
        })
        .catch(e => {
          this.handleGetDataResponse(e.response)
        })
        .finally(() => {
        })
    },
    handleGetDataResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data.Id && data.MemberId && data.Uid) {
        this.onGetDataSuccess(response, data)
      } else {
        this.onGetDataError(response, errors)
      }
    },
    onGetDataSuccess (response, data) {
      this.model = new VoteModel(data)
      if (this.model.ActionEndedAt) {
        this.stopAutoUpdateApiData()
        this.voteStep = 1
      }
    },
    onGetDataError (response, errors) {
      this.$router.replace({
        name  : 'Home',
        params: {
          errors : response?.data?.errors || {},
          message: response?.data?.message || ''
        }
      })
    }
  }
}

</script>

<style scoped>
/deep/ .v-stepper__step {
  padding: 6px 24px;
}

/deep/ .v-input__append-outer {
  margin-top: 0;
}

/deep/ .v-stepper__header > hr {
  margin-top: 19px !important
}

/deep/ .editor-content p {
  margin-bottom: 0;
}

@media only screen and (max-width: 959px) {
  /deep/ .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }

  /deep/ .v-stepper__label {
    font-size: 11px;
  }

  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 110px;
  }

  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -35px 0;
  }
}
</style>
