import BaseResource from '@/lib/data/resource/BaseResource'

export default class VoteResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.MemberId = payload.MemberId ?? null
    this.Uid = payload.Uid ?? ''
    this.CompanyId = payload.CompanyId ?? null
    this.CompanyName = payload.CompanyName ?? ''
    this.Name = payload.Name ?? ''
    this.Notes = payload.Notes ?? ''
    this.Description = payload.Description ?? ''
    this.Categories = payload.Categories ?? []
    this.StartedAt = payload.StartedAt ?? ''
    this.EndedAt = payload.EndedAt ?? ''
    this.ActionStartedAt = payload.ActionStartedAt ?? ''
    this.ActionEndedAt = payload.ActionEndedAt ?? ''
    this.ActionVotesAt = payload.ActionVotesAt ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
