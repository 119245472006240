<template>
  <v-card
    class="elevation-12 d-inline-block"
    min-width="280"
    width="650"
  >
    <v-card-text>
      <div class="text-center">
        <v-sheet
          :color="$vuetify.theme.dark ? '#303030' : 'grey lighten-3'"
          class="pa-6 rounded"
        >
          <div class="title">
            {{ $t('Home.Company.Name') }}
          </div>

          <div class="ma-4">
            <v-img
              contain
              height="200"
              position="center"
              src="/img/logo/logo-dark.png"
            />
          </div>

          <div class="subtitle-2">
            {{ $t('Home.Company.Description') }}
          </div>

          <div
            v-if="errorMessage"
            class="mt-6"
          >
            <v-alert
              class="subtitle-1 font-weight-bold"
              color="warning"
              dense
            >
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'Home',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {}
  },
  computed: {
    errorMessage () {
      if (this.$route?.params?.errors?.Id?.length) return this.$route?.params?.errors?.Id[0]
      if (this.$route?.params?.errors?.MemberId?.length) return this.$route?.params?.errors?.MemberId[0]

      return this.$route?.params?.errors && this.$route?.params?.message ? this.$route?.params?.message : ''
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}

</script>

<style scoped>

</style>
