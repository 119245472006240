import { API } from '@/api/Api'

export default {

  async Get (hash, data = {}) {
    const Endpoint = API.Endpoint.Home.Get
    Endpoint.Path = Endpoint.Path.replace('{hash}', hash)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Save (hash, data = {}) {
    const Endpoint = API.Endpoint.Home.Save
    Endpoint.Path = Endpoint.Path.replace('{hash}', hash)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  }
}
