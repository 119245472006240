import Common from './Common'
import Route  from './Route'
import Home   from './Home'
import Vote   from './Vote'

export default {
  Common: Common,
  Route : Route,
  Home  : Home,
  Vote  : Vote
}
