export default {
  Home: {
    Title: '@:Home.Title',
    Icon : 'mdi-view-dashboard',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Home.Title',
      Desc : '',
      Img  : ''
    }
  },

  Vote: {
    Title: '@:Vote.Title',
    Icon : 'mdi-view-dashboard',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Vote.Title',
      Desc : '',
      Img  : ''
    }
  }
}
