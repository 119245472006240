import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFooter,{attrs:{"app":"","clipped-left":"","dark":"","fixed":"","padless":""}},[_c(VRow,{staticClass:"align-center",attrs:{"dense":""}},[_c(VCol,{staticClass:"shrink",style:(("min-width: " + (_vm.$vuetify.breakpoint.xsOnly ? 68 : 75) + "px;")),on:{"click":function($event){_vm.showVersion = !_vm.showVersion}}},[_c(VSlideXTransition,[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVersion),expression:"showVersion"}],staticClass:"caption ml-1 grey--text text--darken-2"},[_vm._v("v"+_vm._s(_vm.$dataStore.App.Version))])])],1),_c(VCol,[_c('div',{staticClass:"pa-1 text-center",style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 11px' : 'font-size: 12px')},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © "),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('Common.App.Title')))])])]),_c(VCol,{staticClass:"text-right shrink"},[(_vm.$route.meta && _vm.$route.meta.layout && _vm.$route.meta.layout.toLowerCase() !== 'login')?_c(VBtn,{staticClass:"mr-1",attrs:{"depressed":"","fab":"","x-small":""},on:{"click":_vm.toggleThemeDark}},[_c(VIcon,[_vm._v("mdi mdi-invert-colors")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }