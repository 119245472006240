import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

// eslint-disable-next-line no-unused-vars
const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')
const Home = () => themeUtils.importThemeComponent('views/home/Home')
const Vote = () => themeUtils.importThemeComponent('views/home/Vote')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: '/' + i18n.locale
    }
  ],

  LangRoutes: [
    {
      name     : 'Home',
      path     : '',
      component: Home,
      meta     : {
        layout: 'default'
      }
    },
    {
      name     : 'Vote',
      path     : 'vote/:hash',
      component: Vote,
      meta     : {
        layout: 'default'
      }
    }
  ]
}

export default AppRoutes
